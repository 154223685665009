import { Grid, VStack, Button, Link } from '@chakra-ui/react'
import NextLink from 'next/link'

import { BlogListItem } from '@/features/blogs/types/blog'

import { BLOGS as BLOGS_ROUTE } from '@/routes'
import { logEvent } from '@/lib/gtm'

import BlogItem from './BlogItem'

interface Props {
  blogs: BlogListItem[]
  id?: string
}

function BlogList({ blogs }: Props): React.ReactElement {
  return (
    <VStack
      spacing="16"
      sx={{
        alignItems: 'stretch',
        w: 'full',
      }}
    >
      <Grid
        sx={{
          w: 'full',
          gridTemplateColumns: {
            base: '1fr',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(4, 1fr)',
          },
          gridGap: 5,
        }}
      >
        {blogs.slice(0, 4).map((datum) => {
          return <BlogItem key={datum.slug} data={datum} />
        })}
      </Grid>
      <NextLink
        passHref
        href={{
          pathname: BLOGS_ROUTE.pathname,
        }}
      >
        <Link sx={{ alignSelf: 'center', textDecoration: 'none !important' }}>
          <Button
            variant="outline"
            colorScheme="primary"
            size="xl"
            onClick={() => {
              logEvent({
                ga: {
                  category: 'Blog',
                  action: 'Click view blog',
                  label: 'View all blog',
                },
              })
            }}
            sx={{
              fontSize: {
                base: 'xl',
                md: '2xl',
              },
            }}
          >
            อ่านบทความอื่นๆ
          </Button>
        </Link>
      </NextLink>
    </VStack>
  )
}

export default BlogList
