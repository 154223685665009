import dayjs, { Dayjs } from 'dayjs'

export function getDateString(day: Dayjs | Date): string {
  if (day instanceof Date) {
    day = dayjs(day)
  }

  return day.format('DD/MM/YYYY')
}

export function getLocaleString(day: Dayjs | Date): string {
  if (day instanceof Date) {
    day = dayjs(day)
  }

  return day.format('MMMM DD, YYYY')
}
