import { HStack, Link, Icon, Spacer, AspectRatio } from '@chakra-ui/react'
import Image from 'next/image'

import { ReactComponent as LogoFacebook } from '@/svg/logo-facebook.svg'
import { ReactComponent as LogoLinkedin } from '@/svg/logo-linkedin.svg'
import { ReactComponent as LogoDW } from '@/svg/logo-dw.svg'

function Contact(): React.ReactElement {
  return (
    <HStack
      spacing="6"
      sx={{
        alignItems: 'stretch',
        w: 'full',
        textAlign: {
          base: 'center',
          sm: 'left',
        },
        color: '#CCC',
      }}
    >
      <Link
        isExternal
        href="https://www.facebook.com/datawowai"
        aria-label="Data Wow Facebook"
      >
        <Icon
          as={LogoFacebook}
          sx={{
            h: 6,
          }}
        />
      </Link>
      <Link
        isExternal
        href="https://www.linkedin.com/company/datawowio/"
        aria-label="Data Wow LinkedIn"
      >
        <Icon as={LogoLinkedin} boxSize="6" />
      </Link>
      <Link
        isExternal
        href="https://blog.datawow.io/"
        aria-label="Data Wow Blogs"
      >
        <Icon as={LogoDW} boxSize="6" />
      </Link>
      <Spacer />
      <AspectRatio ratio={232 / 89} sx={{ w: { base: '100px', md: '130px' } }}>
        <Image
          src="/images/landing/iso-black.svg"
          alt="ISO"
          quality="100"
          objectFit="contain"
          layout="fill"
        />
      </AspectRatio>
    </HStack>
  )
}

export default Contact
