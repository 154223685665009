import { Text, VStack, Link } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

import { logEvent } from '@/lib/gtm'

function Contact(): React.ReactElement {
  const { t } = useTranslation('common')

  return (
    <VStack
      spacing="0"
      sx={{
        alignItems: 'stretch',
        textAlign: {
          base: 'center',
          sm: 'left',
        },
      }}
    >
      <Text
        sx={{
          fontSize: '3xl',
        }}
      >
        {t('contact.tel.label')}{' '}
        <Link
          isExternal
          href={`tel:${t('contact.tel.number')}`}
          layerStyle="actionable"
          onClick={() => {
            logEvent({
              ga: {
                category: 'Phone',
                action: 'Click Phone',
              },
            })
          }}
        >
          {t('contact.tel.numberLabel')}
        </Link>
      </Text>
      <Link
        isExternal
        href="mailto:sales@datawow.io"
        layerStyle="actionable"
        onClick={() => {
          logEvent({
            ga: {
              category: 'Email',
              action: 'Click Email',
            },
          })
        }}
      >
        <Text
          sx={{
            fontSize: 'xl',
          }}
        >
          sales@datawow.io
        </Text>
      </Link>
    </VStack>
  )
}

export default Contact
