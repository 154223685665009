import { Icon, VStack, AspectRatio, Grid } from '@chakra-ui/react'

import { ReactComponent as Logo } from '@/svg/logo.svg'

import {
  Address,
  Contact as EmailAndTel,
  Links,
} from '@/features/landing/components/Contact'

function Contact(): React.ReactElement {
  return (
    <Grid
      layerStyle="container"
      id="contact"
      as="section"
      sx={{
        gridTemplateColumns: {
          base: '1fr',
          md: 'repeat(2, 1fr)',
        },
        gridGap: {
          base: 8,
          md: 16,
        },
        pb: {
          base: 8,
          md: 16,
        },
        color: 'gray.950',
      }}
    >
      <VStack
        as="article"
        spacing="8"
        sx={{
          alignItems: {
            base: 'center',
            sm: 'flex-start',
          },
        }}
      >
        <Icon as={Logo} sx={{ h: { base: '26px', md: '32px' }, w: 'auto' }} />
        <Address />
        <EmailAndTel />
        <Links />
      </VStack>
      <AspectRatio ratio={551 / 306}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3876.0721984924694!2d100.594277!3d13.714077!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfe14120eb3d8af4f!2sData%20Wow!5e0!3m2!1sen!2sus!4v1604394161891!5m2!1sen!2sus"
          title="datawow-map"
          className="map"
          loading="lazy"
        ></iframe>
      </AspectRatio>
    </Grid>
  )
}

export default Contact
