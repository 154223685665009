import { Text, VStack, Box } from '@chakra-ui/react'

import BlogList from '@/features/landing/components/Blogs'
import { BlogListItem } from '@/features/blogs/types/blog'

export interface Props {
  blogs: BlogListItem[]
}

function Blogs({ blogs }: Props): React.ReactElement {
  return (
    <Box
      as="section"
      id="blogs"
      sx={{
        bg: 'secondary.800',
        w: 'full',
      }}
    >
      <VStack
        spacing={{
          base: 4,
          md: 8,
        }}
        layerStyle="container"
      >
        <VStack
          spacing="1"
          layerStyle="landingSectionHeading"
          sx={{
            alignItems: 'center',
            color: 'white',
          }}
        >
          <Text as="h1" textStyle="landingSectionTitle">
            PDPA Blog
          </Text>
          <Text as="h2" textStyle="landingSectionSubtitle">
            ติดตามบทความ ความรู้เกี่ยวกับ PDPA เพิ่มเติม ที่นี่...
          </Text>
        </VStack>
        <BlogList blogs={blogs} />
      </VStack>
    </Box>
  )
}

export default Blogs
