import { Text, LinkBox, LinkOverlay, useTheme } from '@chakra-ui/react'
import { useSpring } from 'react-spring'
import { useInView } from 'react-intersection-observer'
import Image from 'next/image'
import NextLink from 'next/link'

import { getDateString } from '@/lib/utils/format/datetime'
import {
  DEFAULT_SPRING_CONFIG,
  AnimatedAspectRatio,
  AnimatedVStack,
} from '@/lib/springComponent'
import { logEvent } from '@/lib/gtm'

import { BlogListItem } from '@/features/blogs/types/blog'

import { BLOG } from '@/routes'

interface Props {
  data: BlogListItem
  id?: string
}

function BlogItem({ data, id }: Props): React.ReactElement {
  const theme = useTheme()

  const [imageSpring, imageSpringApi] = useSpring(() => ({
    transform: 'scale(1) translate3d(0, 0%, 0)',
    boxShadow: `0px 0px 0px 0px ${theme.colors.tertiary[400]}88`,
    config: DEFAULT_SPRING_CONFIG,
  }))

  const { ref, inView } = useInView({
    threshold: 0.4,
    triggerOnce: true,
  })

  const containerSpring = useSpring({
    to: {
      transform: inView ? 'translate3d(0, 0px, 0)' : 'translate3d(0, 32px, 0)',
      opacity: inView ? 1 : 0,
    },
    config: DEFAULT_SPRING_CONFIG,
  })

  return (
    <LinkBox as="article" id={id} ref={ref} sx={{ w: 'full' }}>
      <AnimatedVStack
        spacing="4"
        sx={{
          alignItems: 'stretch',
          color: 'white',
        }}
        style={containerSpring}
        onMouseEnter={() => {
          imageSpringApi.start({
            transform: 'scale(1.05) translate3d(0, -5%, 0)',
            boxShadow: `0px 16px 10px -8px ${theme.colors.tertiary[400]}88`,
          })
        }}
        onMouseLeave={() => {
          imageSpringApi.start({
            transform: 'scale(1) translate3d(0, 0%, 0)',
            boxShadow: `0px 0px 0px 0px ${theme.colors.tertiary[400]}88`,
          })
        }}
      >
        <AnimatedAspectRatio ratio={264 / 138} style={imageSpring}>
          <Image
            src={data.image.thumbnail ?? data.image.original}
            title={data.title}
            alt={data.title}
            placeholder="blur"
            blurDataURL={data.image.thumbnail ?? data.image.original}
            objectFit="cover"
            layout="fill"
          />
        </AnimatedAspectRatio>
        <NextLink
          href={{
            pathname: BLOG.pathname,
            query: {
              slug: data.slug,
            },
          }}
          passHref
        >
          <LinkOverlay
            onClick={() => {
              logEvent({ ga: { category: 'Blog', action: 'Click view blog' } })
            }}
          >
            <Text
              as="h2"
              sx={{
                fontWeight: 'medium',
                fontSize: {
                  base: 'md',
                  sm: 'xl',
                },
              }}
            >
              {data.title}
            </Text>
          </LinkOverlay>
        </NextLink>
        <Text
          sx={{
            fontSize: {
              base: 'sm',
              sm: 'md',
            },
          }}
        >
          {getDateString(new Date(data.published_at))}
        </Text>
      </AnimatedVStack>
    </LinkBox>
  )
}

export default BlogItem
